import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Social from '../social/social';

class FranchiseIntro extends Component {
  constructor(props) {
    super(props);
    this.state = { introText: this.props.desc };
  }

  componentDidMount() {
    // PATCH: React 16 is not rehydrating correctly the dangerouslySetInnerHTML html. It seems like there is
    // a difference between the client and server value and the text is removed in the client side.
    // Probably related to this: https://github.com/facebook/react/issues/10993
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ introText: `${this.props.desc}<noscript></noscript>` });
  }

  render() {
    const isGiftGuide = this.props.giftGuide;
    const imageCol = isGiftGuide ? 6 : 4;
    const descCol = isGiftGuide ? 4 : 6;
    return (
      <div className="franchise-intro">
        <Social {...this.props.social} />
        <div className={`franchise-intro__col--${imageCol}`}>
          <img src={this.props.poster} alt={this.props.title} />
        </div>
        <div className={`franchise-intro__col--${descCol}`}>
          <h1 className="franchise-intro__title">{this.props.title}</h1>
          <p
            className="franchise-intro__desc"
            dangerouslySetInnerHTML={{ __html: this.state.introText }}
          />
        </div>
      </div>
    );
  }
}

FranchiseIntro.defaultProps = {
  poster: '',
  title: '',
  desc: '',
  social: {},
  giftGuide: false
};

FranchiseIntro.propTypes = {
  poster: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  social: PropTypes.shape({}),
  giftGuide: PropTypes.bool
};

export default FranchiseIntro;
